import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PortalQuestionaire } from '../portal-questionaire/portal-questionaire';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PortalQuestionaireService {

  //endPointUrl = 'https://dev.ltcstayingsafe.age.ohio.gov/api/Questionnaire';
  //countyEndPointUrl = 'https://dev.ltcstayingsafe.age.ohio.gov/api/Questionnaire/LookUps/County';
  //stateEndPointUrl = 'https://dev.ltcstayingsafe.age.ohio.gov/api/Questionnaire/LookUps/State';
  //cityAndCountyWithZipEndPointUrl = 'https://dev.ltcstayingsafe.age.ohio.gov/api/Questionnaire/LookUps/CityState';

  constructor(private httpClient: HttpClient, private http: ApiService) { }

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  getCounties(): Observable<any> {

    //return this.httpClient.get(this.countyEndPointUrl);
     return this.http.get('Questionnaire/LookUps/County');
  }

  getEnv(): Observable<any> {
    return this.http.get('Config');
      
    
  }

  getStates(): Observable<any> {

    //return this.httpClient.get(this.stateEndPointUrl);
    return this.http.get('Questionnaire/LookUps/State');
  }

  getCityAndCountyWithZip(zip: any): Observable<any> {

    //return this.httpClient.get(this.cityAndCountyWithZipEndPointUrl + '/' + zip.ZipCode);
    return this.http.get(`Questionnaire/LookUps/CityState/${zip.ZipCode}`);
  }

  savePpqQuestionaire(portalQuestionaire: PortalQuestionaire): Observable<any> {
    this.prepareDemographicCheckData(portalQuestionaire);
    this.prepareFunctionalNeedsCheckData(portalQuestionaire);
    this.prepareHousingUtilsCheckData(portalQuestionaire);
    this.prepareTransportationCheckData(portalQuestionaire);

    return this.http.post('Questionnaire', portalQuestionaire);
  }

  prepareDemographicCheckData(portalQuestionaire: PortalQuestionaire) {
    if (portalQuestionaire.ppq_Race_Ai_An_Fl)
      portalQuestionaire.ppq_Race_Ai_An_Fl = 'Y';
    else {
      portalQuestionaire.ppq_Race_Ai_An_Fl = 'N';
    }

    if (portalQuestionaire.ppq_Race_Asian_Fl)
      portalQuestionaire.ppq_Race_Asian_Fl = 'Y';
    else {
      portalQuestionaire.ppq_Race_Asian_Fl = 'N';
    }

    if (portalQuestionaire.ppq_Race_Black_Aa_Fl)
      portalQuestionaire.ppq_Race_Black_Aa_Fl = 'Y';
    else {
      portalQuestionaire.ppq_Race_Black_Aa_Fl = 'N';
    }

    if (portalQuestionaire.ppq_Race_Nh_Op_Fl)
      portalQuestionaire.ppq_Race_Nh_Op_Fl = 'Y';
    else {
      portalQuestionaire.ppq_Race_Nh_Op_Fl = 'N';
    }

    if (portalQuestionaire.ppq_Race_White_Fl)
      portalQuestionaire.ppq_Race_White_Fl = 'Y';
    else {
      portalQuestionaire.ppq_Race_White_Fl = 'N';
    }

    if (portalQuestionaire.ppq_Race_Other_Fl)
      portalQuestionaire.ppq_Race_Other_Fl = 'Y';
    else {
      portalQuestionaire.ppq_Race_Other_Fl = 'N';
    }

  }

  prepareFunctionalNeedsCheckData(portalQuestionaire: PortalQuestionaire) {
    if (portalQuestionaire.ppq_F_Need_Bath_Shower_Fl)
      portalQuestionaire.ppq_F_Need_Bath_Shower_Fl = 'Y';
    else {
      portalQuestionaire.ppq_F_Need_Bath_Shower_Fl = 'N';
    }

    if (portalQuestionaire.ppq_F_Need_Going_Bathroom_Fl)
      portalQuestionaire.ppq_F_Need_Going_Bathroom_Fl = 'Y';
    else {
      portalQuestionaire.ppq_F_Need_Going_Bathroom_Fl = 'N';
    }

    if (portalQuestionaire.ppq_F_Need_Groom_Fl)
      portalQuestionaire.ppq_F_Need_Groom_Fl = 'Y';
    else {
      portalQuestionaire.ppq_F_Need_Groom_Fl = 'N';
    }

    if (portalQuestionaire.ppq_F_Need_Eat_Feed_Fl)
      portalQuestionaire.ppq_F_Need_Eat_Feed_Fl = 'Y';
    else {
      portalQuestionaire.ppq_F_Need_Eat_Feed_Fl = 'N';
    }

    if (portalQuestionaire.ppq_F_Need_Getting_Dressed_Fl)
      portalQuestionaire.ppq_F_Need_Getting_Dressed_Fl = 'Y';
    else {
      portalQuestionaire.ppq_F_Need_Getting_Dressed_Fl = 'N';
    }

    if (portalQuestionaire.ppq_F_Need_Moving_House_Fl)
      portalQuestionaire.ppq_F_Need_Moving_House_Fl = 'Y';
    else {
      portalQuestionaire.ppq_F_Need_Moving_House_Fl = 'N';
    }

    if (portalQuestionaire.ppq_F_Need_Cook_Prep_Meal_Fl)
      portalQuestionaire.ppq_F_Need_Cook_Prep_Meal_Fl = 'Y';
    else {
      portalQuestionaire.ppq_F_Need_Cook_Prep_Meal_Fl = 'N';
    }

    if (portalQuestionaire.ppq_F_Need_Cleaning_House_Fl)
      portalQuestionaire.ppq_F_Need_Cleaning_House_Fl = 'Y';
    else {
      portalQuestionaire.ppq_F_Need_Cleaning_House_Fl = 'N';
    }

    if (portalQuestionaire.ppq_F_Need_Money_Managing_Fl)
      portalQuestionaire.ppq_F_Need_Money_Managing_Fl = 'Y';
    else {
      portalQuestionaire.ppq_F_Need_Money_Managing_Fl = 'N';
    }

    if (portalQuestionaire.ppq_F_Need_Make_Appt_Fl)
      portalQuestionaire.ppq_F_Need_Make_Appt_Fl = 'Y';
    else {
      portalQuestionaire.ppq_F_Need_Make_Appt_Fl = 'N';
    }

    if (portalQuestionaire.ppq_F_Need_Taking_Medicatn_Fl)
      portalQuestionaire.ppq_F_Need_Taking_Medicatn_Fl = 'Y';
    else {
      portalQuestionaire.ppq_F_Need_Taking_Medicatn_Fl = 'N';
    }

    if (portalQuestionaire.ppq_F_Need_Nursing_Serving_Fl)
      portalQuestionaire.ppq_F_Need_Nursing_Serving_Fl = 'Y';
    else {
      portalQuestionaire.ppq_F_Need_Nursing_Serving_Fl = 'N';
    }

    if (portalQuestionaire.ppq_F_Need_Something_Else_Fl)
      portalQuestionaire.ppq_F_Need_Something_Else_Fl = 'Y';
    else {
      portalQuestionaire.ppq_F_Need_Something_Else_Fl = 'N';
    }

  }

  prepareHousingUtilsCheckData(portalQuestionaire: PortalQuestionaire) {

    if (portalQuestionaire.ppq_H_Utils_Bug_Infestion_Fl)
      portalQuestionaire.ppq_H_Utils_Bug_Infestion_Fl = 'Y';
    else {
      portalQuestionaire.ppq_H_Utils_Bug_Infestion_Fl = 'N';
    }

    if (portalQuestionaire.ppq_H_Utils_Mold_Fl)
      portalQuestionaire.ppq_H_Utils_Mold_Fl = 'Y';
    else {
      portalQuestionaire.ppq_H_Utils_Mold_Fl = 'N';
    }

    if (portalQuestionaire.ppq_H_Utils_Heat_Cool_Fl)
      portalQuestionaire.ppq_H_Utils_Heat_Cool_Fl = 'Y';
    else {
      portalQuestionaire.ppq_H_Utils_Heat_Cool_Fl = 'N';
    }

    if (portalQuestionaire.ppq_H_Utils_Appliances_Nw_Fl)
      portalQuestionaire.ppq_H_Utils_Appliances_Nw_Fl = 'Y';
    else {
      portalQuestionaire.ppq_H_Utils_Appliances_Nw_Fl = 'N';
    }

    if (portalQuestionaire.ppq_H_Utils_Smoke_Nw_Fl)
      portalQuestionaire.ppq_H_Utils_Smoke_Nw_Fl = 'Y';
    else {
      portalQuestionaire.ppq_H_Utils_Smoke_Nw_Fl = 'N';
    }

    if (portalQuestionaire.ppq_H_Utils_Carbon_Nw_Fl)
      portalQuestionaire.ppq_H_Utils_Carbon_Nw_Fl = 'Y';
    else {
      portalQuestionaire.ppq_H_Utils_Carbon_Nw_Fl = 'N';
    }

    if (portalQuestionaire.ppq_H_Utils_Plumbing_Fl)
      portalQuestionaire.ppq_H_Utils_Plumbing_Fl = 'Y';
    else {
      portalQuestionaire.ppq_H_Utils_Plumbing_Fl = 'N';
    }

    if (portalQuestionaire.ppq_H_Utils_Accessibility_Fl)
      portalQuestionaire.ppq_H_Utils_Accessibility_Fl = 'Y';
    else {
      portalQuestionaire.ppq_H_Utils_Accessibility_Fl = 'N';
    }

    if (portalQuestionaire.ppq_H_Utils_Somethingelse_Fl)
      portalQuestionaire.ppq_H_Utils_Somethingelse_Fl = 'Y';
    else {
      portalQuestionaire.ppq_H_Utils_Somethingelse_Fl = 'N';
    }

  }

  prepareTransportationCheckData(portalQuestionaire: PortalQuestionaire) {

    if (portalQuestionaire.ppq_Tran_Drive_Have_Car_Fl)
      portalQuestionaire.ppq_Tran_Drive_Have_Car_Fl = 'Y';
    else {
      portalQuestionaire.ppq_Tran_Drive_Have_Car_Fl = 'N';
    }

    if (portalQuestionaire.ppq_Tran_Take_public_Tran_Fl)
      portalQuestionaire.ppq_Tran_Take_public_Tran_Fl = 'Y';
    else {
      portalQuestionaire.ppq_Tran_Take_public_Tran_Fl = 'N';
    }

    if (portalQuestionaire.ppq_Tran_Someoneelse_Drive_Fl)
      portalQuestionaire.ppq_Tran_Someoneelse_Drive_Fl = 'Y';
    else {
      portalQuestionaire.ppq_Tran_Someoneelse_Drive_Fl = 'N';
    }

    if (portalQuestionaire.ppq_Tran_Trouble_Get_Tran_Fl)
      portalQuestionaire.ppq_Tran_Trouble_Get_Tran_Fl = 'Y';
    else {
      portalQuestionaire.ppq_Tran_Trouble_Get_Tran_Fl = 'N';
    }

  }


}

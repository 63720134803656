import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SharedDialogComponent } from '../shared/shared-dialog/shared-dialog.component';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(
    private loaderService: LoaderService,
    private dialog: MatDialog) { }


  public ShowMessage(message: string, title?: string): Observable<any> {
    return this.dialog.open(SharedDialogComponent, {
      disableClose: true,
      data: { content: message, title: title }
    }).afterClosed();
  }

  public ShowConfirm(message: string, title?: string): Observable<any> {
    return this.dialog.open(SharedDialogComponent, {
      disableClose: true,
      data: { title: title, content: message, confirm: true }
    }).afterClosed();
  }

  clearLoader() {
    this.loaderService.hideLoader();
  }

  showLoader() {
    this.loaderService.showLoader();
  }
}

<div class="container">
  <div class="panel panel-danger top15">
    <div class="panel-heading panel-danger"><b>Long Term Services and Supports Inquiry</b></div>
    <div class="panel-body">
      <form class="login-form center-block"
            [formGroup]="portalQuestionaireForm"
            #form="ngForm">
        <mat-card class="mat-elevation-z8">
          <div class="introBlock">

            <div class="introText">
              This form is designed to connect older Ohioans with services and supports that match their needs. The
              information you provide will be sent to a community resource expert who will contact the person needing
              assistance about next steps.

            </div>
            <div class="onScreenTitle">
              <b>On-Screen Instructions </b>
            </div>
            <div class="questionText">
              Please fill out as much information as you can. Questions with an asterisk (<span class='astriskRed'> *
              </span>) must be answered for you to be able to submit this form.
            </div>
            <div class="questionText">
              <ul>
                <li>
                  "Applicant" is the person who needs services.
                </li>
                <li>
                  "Submitter" is a person who is submitting this form on behalf of someone else.
                </li>
              </ul>
            </div>
            <div class="questionText">
              1. Are you completing this form for yourself or for someone else? <span class='astriskRed'> * </span>
              <br />
            </div>
            <div class="row">
              <div class="questionText">
                <div class="col-sm-2">
                  <input type="radio"
                         id="self"
                         name="ppq_Form_Submitter"
                         formControlName="ppq_Form_Submitter"
                         [checked]="true"
                         value="self"
                         (click)="displaySelfApplicantInfo();">
                  <label for="self">Myself</label>
                </div>
                <div class="col-sm-2">
                  <input type="radio"
                         id="someoneElse"
                         name="ppq_Form_Submitter"
                         formControlName="ppq_Form_Submitter"
                         value="someoneElse"
                         (click)="displaySomeOneApplicantInfo();">
                  <label for="someoneElse">Someone Else</label>
                </div>
              </div>
            </div>

            <div *ngIf="isForSomeOne">
              <div class="questionText questionTextpad">
                Who should we call back about this form?
              </div>

              <div class="questionText questionTextpad">
                <div class="row">
                  <div class="col-sm-2">
                    <input type="radio"
                           id="yourName"
                           name="ppq_Callback_Name"
                           formControlName="ppq_Callback_Name"
                           value="submitter"
                           (click)="madateSubmitterInfo('isSubmitter');">
                    <label for="yourName">Submitter</label>
                  </div>
                  <div class="col-sm-2">
                    <input type="radio"
                           id="applicant"
                           name="ppq_Callback_Name"
                           [checked]="true"
                           formControlName="ppq_Callback_Name"
                           value="applicant"
                           (click)="madateSubmitterInfo('isApplicant');">
                    <label for="applicant">Applicant</label>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </mat-card>
        <div class="row">
          &nbsp;
        </div>
        <div>
          <mat-card class="mat-elevation-z8">
            <mat-card-header>
              <mat-card-title>Applicant Information </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <fieldset>
                <div class="row">
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Max 60 characters">
                      <mat-label class="asterisk_input">First Name</mat-label>
                      <input matInput
                             maxlength="60"
                             id="selfFirstName"
                             name="ppq_Firstname"
                             formControlName="ppq_Firstname"
                             placeholder="First Name"
                             type="text">
                      <mat-error *ngIf="portalQuestionaireForm.get('ppq_Firstname')?.errors?.required">
                        Required
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Max 60 characters">
                      <mat-label class="asterisk_input">Last Name</mat-label>
                      <input matInput
                             maxlength="60"
                             id="selfLastName"
                             name="ppq_Lastname"
                             formControlName="ppq_Lastname"
                             placeholder="Last Name"
                             type="text">
                      <mat-error *ngIf="portalQuestionaireForm.get('ppq_Lastname')?.errors?.required">
                        Required
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Format ###-###-####">
                      <mat-label class="asterisk_input">Phone Number</mat-label>
                      <input formControlName="ppq_Phonenumber"
                             maxlength="14"
                             matInput
                             type="tel"
                             mask="(000) 000-0000"
                             placeholder="(000) 000-0000"
                             #ppqPhone>
                      <mat-error *ngIf="portalQuestionaireForm.get('ppq_Phonenumber')?.errors?.required">
                        Required
                      </mat-error>
                      <mat-error *ngIf="portalQuestionaireForm.get('ppq_Phonenumber')?.errors?.pattern">
                        Invalid Phone
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  &nbsp;
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Max 60 characters">
                      <!--<mat-label class="asterisk_input">Email Address</mat-label>-->
                      <input matInput
                             maxlength="60"
                             id="selfEmailAddress"
                             name="ppq_Email_Address"
                             formControlName="ppq_Email_Address"
                             placeholder="Email Address"
                             type="text">
                      <!--<mat-error *ngIf="portalQuestionaireForm.get('ppq_Email_Address')?.errors?.required">
                        Required
                      </mat-error>-->
                      <mat-error *ngIf="portalQuestionaireForm.get('ppq_Email_Address')?.errors?.pattern">
                        Invalid Email
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Max 100 characters">
                      <mat-label class="asterisk_input">Address</mat-label>
                      <input matInput
                             maxlength="100"
                             id="selfAddress"
                             name="ppq_Address"
                             formControlName="ppq_Address"
                             placeholder="Address"
                             type="text">
                      <mat-error *ngIf="portalQuestionaireForm.get('ppq_Address')?.errors?.required">
                        Required
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Max 5 characters">
                      <mat-label class="asterisk_input">Zip Code</mat-label>
                      <input matInput
                             id="selfZip"
                             name="ppq_Zip"
                             maxlength="5"
                             appOnlyNumbers
                             formControlName="ppq_Zip"
                             placeholder="Zip Code"
                             type="text">
                      <mat-error *ngIf="portalQuestionaireForm.get('ppq_Zip')?.errors?.required">
                        Required
                      </mat-error>
                      <mat-error *ngIf="portalQuestionaireForm.get('ppq_Zip')?.errors?.invalidZip">
                        Please enter valid OH Zip Code.
                      </mat-error>
                      <mat-error *ngIf="portalQuestionaireForm.get('ppq_Zip')?.errors?.pattern">
                        Invalid Zip Code
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  &nbsp;
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Applicant's City">
                      <!--<mat-label class="asterisk_input">City</mat-label>-->
                      <input matInput
                             maxlength="100"
                             id="selfCity"
                             name="ppq_City"
                             formControlName="ppq_City"
                             placeholder="City"
                             type="text"
                             readonly>
                      <!--<mat-error *ngIf="portalQuestionaireForm.get('ppq_City')?.errors?.required">
          Required
        </mat-error>-->
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Applicant's State">
                      <input matInput
                             maxlength="60"
                             id="selfState"
                             name="ppq_State"
                             formControlName="ppq_State"
                             placeholder="State"
                             type="text"
                             readonly>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4">
                    <!--<mat-form-field class="login-full-width"
                  hintLabel="Applicant's County">
    <mat-label class="asterisk_input">County</mat-label>
    <mat-select placeholder="County"
                formControlName="ppq_County" [disabled]="true">
      <mat-option *ngFor="let county of counties"
                  [value]="county.countyCode" readonly>
        {{ county.countyName }}
      </mat-option>
    </mat-select>
  </mat-form-field>-->
                    <mat-form-field class="login-full-width"
                                    hintLabel="Applicant's County">
                      <input matInput
                             id="selfCounty"
                             name="ppq_County"
                             formControlName="ppq_County"
                             placeholder="County"
                             type="text"
                             readonly>
                    </mat-form-field>
                  </div>
                </div>

              </fieldset>
              <div class="row">
                &nbsp;
              </div>
            </mat-card-content>
          </mat-card>

          <div class="row">
            &nbsp;
          </div>


        </div>

        <div *ngIf="isForSomeOne">
          <mat-card class="mat-elevation-z8">
            <mat-card-header>
              <mat-card-title>Submitter Information </mat-card-title>
            </mat-card-header>
            <fieldset>
              <div class="row">
                <div *ngIf="isSubmitter">
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Max 60 characters">
                      <mat-label class="asterisk_input">First Name</mat-label>
                      <input matInput
                             id="submitterFirstName"
                             name="ppq_Submitter_Firstname"
                             formControlName="ppq_Submitter_Firstname"
                             maxlength="60"
                             placeholder="First Name"
                             type="text">
                      <mat-error *ngIf="portalQuestionaireForm.get('ppq_Submitter_Firstname')?.errors?.required">
                        Required
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Max 60 characters">
                      <mat-label class="asterisk_input">Last Name</mat-label>
                      <input matInput
                             id="submitterLastName"
                             name="ppq_Submitter_Lastname"
                             formControlName="ppq_Submitter_Lastname"
                             maxlength="60"
                             placeholder="Last Name"
                             type="text">
                      <mat-error *ngIf="portalQuestionaireForm.get('ppq_Submitter_Lastname')?.errors?.required">
                        Required
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Format ###-###-####">
                      <mat-label class="asterisk_input">Phone Number</mat-label>
                      <input matInput
                             id="submitterPhoneNumber"
                             name="ppq_Submitter_Phonenumber"
                             formControlName="ppq_Submitter_Phonenumber"
                             maxlength="14"
                             matInput
                             placeholder="Phone Number"
                             type="tel"
                             mask="(000) 000-0000"
                             placeholder="(000) 000-0000"
                             valueType='full'>
                      <mat-error *ngIf="portalQuestionaireForm.get('ppq_Submitter_Phonenumber')?.errors?.required">
                        Required
                      </mat-error>
                      <mat-error *ngIf="portalQuestionaireForm.get('ppq_Submitter_Phonenumber')?.errors?.pattern">
                        Invalid Phone
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>


                <div *ngIf="isApplicant">
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Max 60 characters">
                      <input matInput
                             id="submitterFirstName"
                             name="ppq_Submitter_Firstname"
                             formControlName="ppq_Submitter_Firstname"
                             maxlength="60"
                             placeholder="First Name"
                             type="text">
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Max 60 characters">
                      <input matInput
                             id="submitterLastName"
                             name="ppq_Submitter_Lastname"
                             formControlName="ppq_Submitter_Lastname"
                             maxlength="60"
                             placeholder="Last Name"
                             type="text">
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Format ###-###-####">
                      <mat-label>Phone Number</mat-label>
                      <input matInput
                             id="submitterPhoneNumber"
                             name="ppq_Submitter_Phonenumber"
                             formControlName="ppq_Submitter_Phonenumber"
                             maxlength="14"
                             matInput
                             placeholder="Phone Number"
                             type="tel"
                             mask="(000) 000-0000"
                             placeholder="(000) 000-0000"
                             valueType='full'>
                    </mat-form-field>
                    <mat-error *ngIf="portalQuestionaireForm.get('ppq_Submitter_Phonenumber')?.errors?.pattern">
                      Invalid Phone
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="row">
                &nbsp;
              </div>
              <div class="row">
                <div *ngIf="isSubmitter">
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Max 60 characters">
                      <!--<mat-label class="asterisk_input">Email Address</mat-label>-->
                      <input matInput
                             id="submitterEmailAddress"
                             name="ppq_Submitter_Email_Address"
                             formControlName="ppq_Submitter_Email_Address"
                             maxlength="60"
                             placeholder="Email Address"
                             type="text">
                      <!--<mat-error *ngIf="portalQuestionaireForm.get('ppq_Submitter_Email_Address')?.errors?.required">
                        Required
                      </mat-error>-->
                      <mat-error *ngIf="portalQuestionaireForm.get('ppq_Submitter_Email_Address')?.errors?.pattern">
                        Invalid Email
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div *ngIf="isApplicant">
                  <div class="col-sm-4">
                    <mat-form-field class="login-full-width"
                                    hintLabel="Max 60 characters">
                      <input matInput
                             id="submitterEmailAddress"
                             name="ppq_Submitter_Email_Address"
                             formControlName="ppq_Submitter_Email_Address"
                             maxlength="60"
                             placeholder="Email Address"
                             type="text">
                      <mat-error *ngIf="portalQuestionaireForm.get('ppq_Submitter_Email_Address')?.errors?.pattern">
                        Invalid Email
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-sm-4">
                  <mat-form-field class="login-full-width"
                                  hintLabel="Max 100 characters">
                    <input matInput
                           id="submitterAddress"
                           name="ppq_Submitter_Address"
                           formControlName="ppq_Submitter_Address"
                           maxlength="100"
                           placeholder="Address"
                           type="text">
                  </mat-form-field>
                </div>
                <div class="col-sm-4">
                  <mat-form-field class="login-full-width"
                                  hintLabel="Max 5 characters">
                    <mat-label>Zip Code</mat-label>
                    <input matInput
                           id="submitterZip"
                           name="ppq_Submitter_Zip"
                           appOnlyNumbers
                           formControlName="ppq_Submitter_Zip"
                           maxlength="5"
                           placeholder="ZipCode"
                           type="text">
                    <mat-error *ngIf="portalQuestionaireForm.get('ppq_Submitter_Zip')?.errors?.pattern">
                      Invalid Zip Code
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                &nbsp;
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <mat-form-field class="login-full-width"
                                  hintLabel="Max 30 characters">
                    <input matInput
                           id="submitterCity"
                           name="ppq_Submitter_City"
                           formControlName="ppq_Submitter_City"
                           maxlength="12"
                           placeholder="City"
                           type="text">
                  </mat-form-field>
                </div>
                <div class="col-sm-4">
                  <mat-form-field class="login-full-width"
                                  hintLabel="Submitter's State">
                    <mat-select placeholder="State"
                                formControlName="ppq_Submitter_State">
                      <mat-option *ngFor="let state of states"
                                  [value]="state.domainValue">
                        {{ state.domainExpandedValue }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-sm-4">
                  <mat-form-field class="login-full-width"
                                  hintLabel="Max 30 characters">
                    <input matInput
                           id="submitterCounty"
                           name="ppq_Submitter_County"
                           formControlName="ppq_Submitter_County"
                           placeholder="County"
                           maxlength="30"
                           type="text">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                &nbsp;
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <mat-form-field class="login-full-width"
                                  style="width: inherit;"
                                  hintLabel="Max 100 characters">
                    <input matInput
                           id="submitterApplicantSubmitterRelation"
                           name="ppq_Submitter_Relation"
                           formControlName="ppq_Submitter_Relation"
                           maxlength="100"
                           placeholder="Relationship with Applicant"
                           type="text">
                  </mat-form-field>
                </div>
              </div>

            </fieldset>
            <div class="row">
              &nbsp;
            </div>
          </mat-card>

          <div class="row">
            &nbsp;
          </div>
        </div>

        <div>
          <mat-card class="mat-elevation-z8">
            <mat-card-header>
              <mat-card-title>Applicant Demographics</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <fieldset>
                <div class="questionText">
                  <mat-form-field>
                    <span>1. Age</span>
                    <input type="text"
                           name="ppq_Age"
                           type="text"
                           maxlength="3"
                           matInput
                           appOnlyNumbers
                           step="1"
                           min="0"
                           formControlName="ppq_Age" />
                    <mat-error *ngIf="portalQuestionaireForm.get('ppq_Age')?.errors?.pattern">
                      Invalid Age.
                    </mat-error>
                    <mat-error *ngIf="portalQuestionaireForm.get('ppq_Age')?.errors?.max">
                      Age range should be between 1 to 120 years.
                    </mat-error>
                    <mat-error *ngIf="portalQuestionaireForm.get('ppq_Age')?.errors?.min">
                      Age range should be between 1 to 120 years.
                    </mat-error>
                  </mat-form-field>
                  <br />

                </div>
                <div class="questionText">
                  2. Sex :
                  <div class="row">
                    <div class="col-sm-2">
                      <input type="radio"
                             id="male"
                             name="ppq_Sex"
                             formControlName="ppq_Sex"
                             (click)='disableSexOther()'
                             value="M">
                      <label for="male">Male</label>
                    </div>
                    <div class="col-sm-2">
                      <input type="radio"
                             id="female"
                             name="ppq_Sex"
                             formControlName="ppq_Sex"
                             (click)='disableSexOther()'
                             value="F">
                      <label for="female">Female</label>
                    </div>
                    <div style="padding-right: 32px;float: left;">
                      <input type="radio"
                             id="other"
                             name="ppq_Sex"
                             formControlName="ppq_Sex"
                             (click)='enableSexOther()'
                             value="O">
                      <label for="other">Other</label>
                    </div>
                    <div *ngIf="isEnableSexOther">
                      <mat-form-field class="col-sm-3">
                        <textarea name="ppq_Sex_Other_Desc"
                                  formControlName="ppq_Sex_Other_Desc"
                                  placeholder="Enter Description"
                                  cols="50"
                                  matInput
                                  #ppq_Sex_Other_Desc
                                  maxlength="50"
                                  rows="3"></textarea>
                        <mat-hint align="start">Maximum 50 characters</mat-hint>
                        <mat-hint align="end">{{ ppq_Sex_Other_Desc.value.length }} / 50</mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="questionText">
                  3. Race: <br />
                  <div class="row">
                    <div class="col-sm-4">
                      <input type="checkbox"
                             id="raceAIAN"
                             name="ppq_Race_Ai_An_Fl"
                             formControlName="ppq_Race_Ai_An_Fl">
                      <label for="raceAIAN"> American Indian or Alaskan Native </label>
                    </div>
                    <div class="col-sm-3">
                      <input type="checkbox"
                             id="raceAsian"
                             name="ppq_Race_Asian_Fl"
                             formControlName="ppq_Race_Asian_Fl">
                      <label for="raceAsian"> Asian </label>
                    </div>
                    <div class="col-sm-4">
                      <input type="checkbox"
                             id="raceBlackAA"
                             name="ppq_Race_Black_Aa_Fl"
                             formControlName="ppq_Race_Black_Aa_Fl">
                      <label for="raceBlackAA"> Black or African American</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <input type="checkbox"
                             id="raceNHOPI"
                             name="ppq_Race_Nh_Op_Fl"
                             formControlName="ppq_Race_Nh_Op_Fl">
                      <label for="raceNHOPI"> Native Hawaiian or Other Pacific Islander </label>
                    </div>
                    <div class="col-sm-3">
                      <input type="checkbox"
                             id="raceWhite"
                             name="ppq_Race_White_Fl"
                             formControlName="ppq_Race_White_Fl">
                      <label for="raceWhite"> White </label>
                    </div>
                    <div class="col-sm-3">
                      <input type="checkbox"
                             id="raceOther"
                             name="ppq_Race_Other_Fl"
                             formControlName="ppq_Race_Other_Fl"
                             (click)="enableRaceOther()">
                      <label for="raceOther"> Other </label>
                      <div *ngIf="isEnableRaceOther">
                        <mat-form-field class="col-sm-12">
                          <textarea name="ppq_Race_Other_Desc"
                                    formControlName="ppq_Race_Other_Desc"
                                    placeholder="Enter Description"
                                    cols="50"
                                    matInput
                                    #ppq_Race_Other_Desc
                                    maxlength="50"
                                    rows="3"></textarea>
                          <mat-hint align="start">Maximum 50 characters</mat-hint>
                          <mat-hint align="end">{{ ppq_Race_Other_Desc.value.length }} / 50</mat-hint>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="questionText">
                  4. Ethnicity :
                  <div class="row">
                    <div class="col-sm-3">
                      <input type="radio"
                             id="ethnicityHispanic"
                             name="ppq_Ethn_Hispanic_Latino_Fl"
                             formControlName="ppq_Ethn_Hispanic_Latino_Fl"
                             value="Y">
                      <label for="ethnicityHispanic">Hispanic or Latino</label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="ethnicityNotHispanic"
                             name="ppq_Ethn_Hispanic_Latino_Fl"
                             formControlName="ppq_Ethn_Hispanic_Latino_Fl"
                             value="N">
                      <label for="ethnicityNotHispanic">Not Hispanic or Latino </label>
                    </div>
                  </div>
                </div>

                <div class="questionText">
                  5. Have you served in the US Military?
                  <div class="row">
                    <div class="col-sm-2">
                      <input type="radio"
                             id="usmilitaryNoServe"
                             name="ppq_Served_Miltary_Fl"
                             formControlName="ppq_Served_Miltary_Fl"
                             value="N">
                      <label for="usmilitaryNoServe">No</label>
                    </div>
                    <div class="col-sm-1">
                      <input type="radio"
                             id="usmilitaryServe"
                             name="ppq_Served_Miltary_Fl"
                             formControlName="ppq_Served_Miltary_Fl"
                             value="Y">
                      <label for="usmilitaryServe">Yes</label>
                    </div>
                  </div>
                </div>

              </fieldset>
            </mat-card-content>
          </mat-card>

          <div class="row">
            &nbsp;
          </div>
        </div>

        <div>
          <mat-card class="mat-elevation-z8">
            <mat-card-header>
              <mat-card-title>Applicant Communication</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <fieldset>
                <div class="questionText">
                  <div class="row">
                    1. Primary Spoken Language: <input type="text"
                           name="ppq_Spoken_Language"
                           formControlName="ppq_Spoken_Language"
                           placeholder="Enter Text" />
                  </div>
                </div>
                <div class="communicationTextLeftAlign">
                  <div class="questionText">
                    <div class="row">
                      <div class="col-sm-3">
                        2. Interpreter requested?
                      </div>
                      <div class="col-sm-1">
                        <input type="radio"
                               id="interpreterReqNo"
                               name="ppq_Interpreter_Requsted_Fl"
                               formControlName="ppq_Interpreter_Requsted_Fl"
                               value="N">
                        <label for="interpreterReqNo">No</label>
                      </div>
                      <div class="col-sm-1">
                        <input type="radio"
                               id="interpreterReqYes"
                               name="ppq_Interpreter_Requsted_Fl"
                               formControlName="ppq_Interpreter_Requsted_Fl"
                               value="Y">
                        <label for="interpreterReqYes">Yes</label>
                      </div>
                    </div>
                  </div>
                  <div class="questionText">
                    <div class="row">
                      <div class="col-sm-3">
                        3. Do you have trouble hearing?
                      </div>
                      <div class="col-sm-1">
                        <input type="radio"
                               id="troubleHearingNo"
                               name="ppq_Trouble_Hearing_Fl"
                               formControlName="ppq_Trouble_Hearing_Fl"
                               value="N">
                        <label for="troubleHearingNo">No</label>
                      </div>
                      <div class="col-sm-1">
                        <input type="radio"
                               id="troubleHearingYes"
                               name="ppq_Trouble_Hearing_Fl"
                               formControlName="ppq_Trouble_Hearing_Fl"
                               value="Y">
                        <label for="troubleHearingYes">Yes</label>
                      </div>
                    </div>
                  </div>
                  <div class="questionText">
                    <div class="row">
                      <div class="col-sm-3">
                        4. Do you have trouble speaking?
                      </div>
                      <div class="col-sm-1">
                        <input type="radio"
                               id="troubleSpeakingNo"
                               name="ppq_Trouble_Speaking_Fl"
                               formControlName="ppq_Trouble_Speaking_Fl"
                               value="N">
                        <label for="troubleSpeakingNo">No</label>
                      </div>
                      <div class="col-sm-1">
                        <input type="radio"
                               id="troubleSpeakingYes"
                               name="ppq_Trouble_Speaking_Fl"
                               formControlName="ppq_Trouble_Speaking_Fl"
                               value="Y">
                        <label for="troubleSpeakingYes">Yes</label>
                      </div>
                    </div>
                  </div>
                </div>

              </fieldset>
            </mat-card-content>

          </mat-card>
          <div class="row">
            &nbsp;
          </div>
        </div>

        <div>
          <mat-card class="mat-elevation-z8">
            <mat-card-header>
              <mat-card-title>Applicant Functional Needs</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <fieldset>
                <div class="questionText">
                  1. Are you interested in learning about resources that could help with any of the following? <br />
                </div>
                <div class="questionText">
                  <div class="row">
                    <div class="col-sm-1">
                      <input type="radio"
                             id="fnHelpNo"
                             name="ppq_F_Need_Fl"
                             formControlName="ppq_F_Need_Fl"
                             (click)="disableFunctionalNeeds()"
                             value="N">
                      <label for="fnHelpNo">No</label>
                    </div>
                    <div class="col-sm-4">
                      <input type="radio"
                             id="fnHelpYes"
                             name="ppq_F_Need_Fl"
                             formControlName="ppq_F_Need_Fl"
                             (click)="enableFunctionalNeeds()"
                             value="Y">
                      <label for="fnHelpYes">Yes, I am interested in help with: </label>
                    </div>
                  </div>
                </div>

                <div>

                  <div class="questionText">
                    <div class="row">
                      <div class="col-sm-1">
                      </div>
                      <div class="col-sm-3">
                        <input type="checkbox"
                               id="fnBathOrShower"
                               name="ppq_F_Need_Bath_Shower_Fl"
                               formControlName="ppq_F_Need_Bath_Shower_Fl">
                        <label for="fnBathOrShower"> Bathing/showering </label>
                      </div>
                      <div class="col-sm-4">
                        <input type="checkbox"
                               id="fnGrooming"
                               name="ppq_F_Need_Groom_Fl"
                               formControlName="ppq_F_Need_Groom_Fl">
                        <label for="fnGrooming"> Grooming (tooth care, nail care, hair care) </label>
                      </div>
                      <div class="col-sm-3">
                        <input type="checkbox"
                               id="fnGoingToBathroom"
                               name="ppq_F_Need_Going_Bathroom_Fl"
                               formControlName="ppq_F_Need_Going_Bathroom_Fl">
                        <label for="fnGoingToBathroom"> Going to the bathroom </label>
                      </div>
                    </div>
                  </div>

                  <div class="questionText">
                    <div class="row">
                      <div class="col-sm-1">
                      </div>
                      <div class="col-sm-3">
                        <input type="checkbox"
                               id="fnEatOrFeed"
                               name="ppq_F_Need_Eat_Feed_Fl"
                               formControlName="ppq_F_Need_Eat_Feed_Fl">
                        <label for="fnEatOrFeed"> Eating/feeding </label>
                      </div>
                      <div class="col-sm-4">
                        <input type="checkbox"
                               id="fnGettingDressed"
                               name="ppq_F_Need_Getting_Dressed_Fl"
                               formControlName="ppq_F_Need_Getting_Dressed_Fl">
                        <label for="fnGettingDressed"> Getting dressed </label>
                      </div>
                      <div class="col-sm-3">
                        <input type="checkbox"
                               id="fnMovingAroundHouse"
                               name="ppq_F_Need_Moving_House_Fl"
                               formControlName="ppq_F_Need_Moving_House_Fl">
                        <label for="fnMovingAroundHouse"> Moving around the house </label>
                      </div>
                    </div>
                  </div>
                  <div class="questionText">
                    <div class="row">
                      <div class="col-sm-1">
                      </div>
                      <div class="col-sm-3">
                        <input type="checkbox"
                               id="fnCookOrPrepMeals"
                               name="ppq_F_Need_Cook_Prep_Meal_Fl"
                               formControlName="ppq_F_Need_Cook_Prep_Meal_Fl">
                        <label for="fnCookOrPrepMeals"> Cooking/preparing meals </label>
                      </div>
                      <div class="col-sm-4">
                        <input type="checkbox"
                               id="fnCleaningHouse"
                               name="ppq_F_Need_Cleaning_House_Fl"
                               formControlName="ppq_F_Need_Cleaning_House_Fl">
                        <label for="fnCleaningHouse"> Cleaning the house </label>
                      </div>
                      <div class="col-sm-3">
                        <input type="checkbox"
                               id="fnManageMoney"
                               name="ppq_F_Need_Money_Managing_Fl"
                               formControlName="ppq_F_Need_Money_Managing_Fl">
                        <label for="fnManageMoney"> Managing money </label>
                      </div>
                    </div>
                  </div>
                  <div class="questionText">
                    <div class="row">
                      <div class="col-sm-1">
                      </div>
                      <div class="col-sm-3">
                        <input type="checkbox"
                               id="fnMakingAppointments"
                               name="ppq_F_Need_Make_Appt_Fl"
                               formControlName="ppq_F_Need_Make_Appt_Fl">
                        <label for="fnMakingAppointments"> Making appointments </label>
                      </div>
                      <div class="col-sm-4">
                        <input type="checkbox"
                               id="fnMedication"
                               name="ppq_F_Need_Taking_Medicatn_Fl"
                               formControlName="ppq_F_Need_Taking_Medicatn_Fl">
                        <label for="fnMedication"> Taking/managing medication </label>
                      </div>
                      <div class="col-sm-3">
                        <input type="checkbox"
                               id="fnNursingServices"
                               name="ppq_F_Need_Nursing_Serving_Fl"
                               formControlName="ppq_F_Need_Nursing_Serving_Fl">
                        <label for="fnNursingServices"> Nursing services </label>
                      </div>
                    </div>
                  </div>
                  <div class="questionText">
                    <div class="row">
                      <div class="col-sm-1">
                      </div>
                      <div class="col-sm-2">
                        <input type="checkbox"
                               id="fnSomethingElse"
                               name="ppq_F_Need_Something_Else_Fl"
                               formControlName="ppq_F_Need_Something_Else_Fl"
                               (click)="enableFNSomethingelseDesc()">
                        <label for="fnSomethingElse"> Something else </label>
                      </div>
                      <div *ngIf="isEnableFNSomethingelseDesc">
                        <mat-form-field class="col-sm-6">
                          <textarea id="fnSomethingElseText"
                                    #fnSomethingElseText
                                    name="ppq_F_Need_Something_Else_Desc"
                                    formControlName="ppq_F_Need_Something_Else_Desc"
                                    placeholder="Enter Description"
                                    cols="50"
                                    maxlength="190"
                                    matInput
                                    rows="3"></textarea>
                          <mat-hint align="start">Maximum 190 characters</mat-hint>
                          <mat-hint align="end">{{ fnSomethingElseText.value.length }} / 190</mat-hint>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

              </fieldset>
            </mat-card-content>
          </mat-card>
          <div class="row">
            &nbsp;
          </div>
        </div>

        <div>
          <mat-card class="mat-elevation-z8">
            <mat-card-header>
              <mat-card-title>Applicant Housing/Utilities</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <fieldset>
                <div class="questionText">
                  1. Where do you live?
                  <div class="row">
                    <div class="col-sm-3">
                      <input type="radio"
                             id="huLiveHouseAptOwn"
                             name="ppq_H_Utils_Where_Live_Fl"
                             formControlName="ppq_H_Utils_Where_Live_Fl"
                             (click)="disableHULiveSomewhereElse()"
                             value="O">
                      <label for="huLiveHouseAptOwn">A house/apartment I own</label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="huLiveHouseAptRent"
                             name="ppq_H_Utils_Where_Live_Fl"
                             formControlName="ppq_H_Utils_Where_Live_Fl"
                             (click)="disableHULiveSomewhereElse()"
                             value="R">
                      <label for="huLiveHouseAptRent">A house/apartment I rent</label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="huLiveSomewhereElse"
                             name="ppq_H_Utils_Where_Live_Fl"
                             formControlName="ppq_H_Utils_Where_Live_Fl"
                             (click)="enableHULiveSomewhereElse()"
                             value="S">
                      <label for="huLiveSomewhereElse">Somewhere else</label>
                      <div *ngIf="isEnableHULiveSomewhereElse">
                        <mat-form-field class="col-sm-12">
                          <textarea id="huLiveSomewhereElseText"
                                    #huLiveSomewhereElseText
                                    name="ppq_H_Utils_Where_Live_Desc"
                                    formControlName="ppq_H_Utils_Where_Live_Desc"
                                    placeholder="Enter Description"
                                    maxlength="100"
                                    cols="50"
                                    matInput
                                    rows="3"></textarea>
                          <mat-hint align="start">Maximum 100 characters</mat-hint>
                          <mat-hint align="end">{{ huLiveSomewhereElseText.value.length }} / 100</mat-hint>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="questionText">
                  2. Who do you live with?
                  <div class="row">
                    <div class="col-sm-3">
                      <input type="radio"
                             id="huLiveWithAlone"
                             name="ppq_H_Utils_Who_Live_Fl"
                             formControlName="ppq_H_Utils_Who_Live_Fl"
                             (click)="disableHULiveWithSomeoneElse()"
                             value="A">
                      <label for="huLiveWithAlone">Alone</label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="huLiveWithSpouse"
                             name="ppq_H_Utils_Who_Live_Fl"
                             formControlName="ppq_H_Utils_Who_Live_Fl"
                             (click)="disableHULiveWithSomeoneElse()"
                             value="P">
                      <label for="huLiveWithSpouse">With a spouse/partner</label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="huLiveWithFamily"
                             name="ppq_H_Utils_Who_Live_Fl"
                             formControlName="ppq_H_Utils_Who_Live_Fl"
                             (click)="disableHULiveWithSomeoneElse()"
                             value="O">
                      <label for="huLiveWithFamily">With other family</label>
                    </div>
                  </div>
                </div>

                <div class="questionText">
                  <div class="row">
                    <div class="col-sm-3">
                      <input type="radio"
                             id="huLiveWithFriends"
                             name="ppq_H_Utils_Who_Live_Fl"
                             formControlName="ppq_H_Utils_Who_Live_Fl"
                             (click)="disableHULiveWithSomeoneElse()"
                             value="F">
                      <label for="huLiveWithFriends">With friends</label>
                    </div>
                    <div class="col-sm-3"
                         style="margin-right: -70px !important;">
                      <input type="radio"
                             id="huLiveWithSomeoneElse"
                             name="ppq_H_Utils_Who_Live_Fl"
                             formControlName="ppq_H_Utils_Who_Live_Fl"
                             (click)="enableHULiveWithSomeoneElse()"
                             value="S">
                      <label for="huLiveWithSomeoneElse">With someone else</label>
                    </div>
                    <div *ngIf="isenableHULiveWithSomeoneElse">
                      <mat-form-field class="col-sm-3">
                        <textarea id="huLiveWithSomeoneElseText"
                                  #huLiveWithSomeoneElseText
                                  name="ppq_H_Utils_Who_Live_Desc"
                                  formControlName="ppq_H_Utils_Who_Live_Desc"
                                  placeholder="Enter Description "
                                  maxlength="70"
                                  cols="50"
                                  matInput
                                  rows="3"></textarea>
                        <mat-hint align="start">Maximum 70 characters</mat-hint>
                        <mat-hint align="end">{{ huLiveWithSomeoneElseText.value.length }} / 70</mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="questionText">
                  3. Are you concerned about losing your housing in the next 3 months?
                  <div class="row">
                    <div class="col-sm-3">
                      <input type="radio"
                             id="huLoseHouseNo"
                             name="ppq_H_Utils_Loose_H_Fl"
                             formControlName="ppq_H_Utils_Loose_H_Fl"
                             value="N">
                      <label for="huLoseHouseNo">No</label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="huLoseHouseYes"
                             name="ppq_H_Utils_Loose_H_Fl"
                             formControlName="ppq_H_Utils_Loose_H_Fl"
                             value="Y">
                      <label for="huLoseHouseYes">Yes</label>
                    </div>
                  </div>
                </div>

                <div class="questionText">
                  4. Do you have problems with the place you live? <br />
                  <div class="row">
                    <div class="col-sm-5">
                      <input type="checkbox"
                             id="huProblemBug"
                             name="ppq_H_Utils_Bug_Infestion_Fl"
                             formControlName="ppq_H_Utils_Bug_Infestion_Fl">
                      <label for="huProblemBug"> Bug infestation </label>
                    </div>
                    <div class="col-sm-3">
                      <input type="checkbox"
                             id="huProblemMold"
                             name="ppq_H_Utils_Mold_Fl"
                             formControlName="ppq_H_Utils_Mold_Fl">
                      <label for="huProblemMold"> Mold </label>
                    </div>
                    <div class="col-sm-4">
                      <input type="checkbox"
                             id="huProblemNoHeatOrCool"
                             name="ppq_H_Utils_Heat_Cool_Fl"
                             formControlName="ppq_H_Utils_Heat_Cool_Fl">
                      <label for="huProblemNoHeatOrCool"> Inadequate heating/cooling</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                      <input type="checkbox"
                             id="huProblemAppliance"
                             name="ppq_H_Utils_Appliances_Nw_Fl"
                             formControlName="ppq_H_Utils_Appliances_Nw_Fl">
                      <label for="huProblemAppliance"> Appliances not working (stove, oven, refrigerator) </label>
                    </div>
                    <div class="col-sm-3">
                      <input type="checkbox"
                             id="huProblemSmokeDetector"
                             name="ppq_H_Utils_Smoke_Nw_Fl"
                             formControlName="ppq_H_Utils_Smoke_Nw_Fl">
                      <label for="huProblemSmokeDetector"> No working smoke detectors </label>
                    </div>
                    <div class="col-sm-4">
                      <input type="checkbox"
                             id="huProblemCODetector"
                             name="ppq_H_Utils_Carbon_Nw_Fl"
                             formControlName="ppq_H_Utils_Carbon_Nw_Fl">
                      <label for="huProblemCODetector"> No working carbon monoxide detectors </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                      <input type="checkbox"
                             id="huPlumbing"
                             name="ppq_H_Utils_Plumbing_Fl"
                             formControlName="ppq_H_Utils_Plumbing_Fl">
                      <label for="huPlumbing"> Plumbing </label>
                    </div>
                    <div class="col-sm-3">
                      <input type="checkbox"
                             id="huAccessibility"
                             name="ppq_H_Utils_Accessibility_Fl"
                             formControlName="ppq_H_Utils_Accessibility_Fl">
                      <label for="huAccessibility"> Accessibility issues </label>
                    </div>
                    <div class="col-sm-4">
                      <input type="checkbox"
                             id="huSomethingElse"
                             name="ppq_H_Utils_Somethingelse_Fl"
                             formControlName="ppq_H_Utils_Somethingelse_Fl"
                             (click)="enableHUProblemSomethingElse()">
                      <label for="huSomethingElse">Something else</label>&nbsp;
                      <div *ngIf="isEnableHUProblemSomethingElse">
                        <mat-form-field class="col-sm-10">
                          <textarea name="ppq_H_Utils_Somethingelse_Desc"
                                    #ppq_H_Utils_Somethingelse_Desc
                                    formControlName="ppq_H_Utils_Somethingelse_Desc"
                                    placeholder="Enter Description"
                                    maxlength="100"
                                    cols="45"
                                    matInput
                                    rows="3"></textarea>
                          <mat-hint align="start">Maximum 100 characters</mat-hint>
                          <mat-hint align="end">{{ ppq_H_Utils_Somethingelse_Desc.value.length }} / 100</mat-hint>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="questionText">
                  5. In the past 12 months, have any utility companies (water, gas, electric) shut off your service?
                  <div class="row">
                    <div class="col-sm-3">
                      <input type="radio"
                             id="huLoseUtilityNo"
                             name="ppq_H_Utils_Shutoff_Fl"
                             formControlName="ppq_H_Utils_Shutoff_Fl"
                             value="N">
                      <label for="huLoseUtilityNo">No</label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="huLoseUtilityNoButNotice"
                             name="ppq_H_Utils_Shutoff_Fl"
                             formControlName="ppq_H_Utils_Shutoff_Fl"
                             value="B">
                      <label for="huLoseUtilityNoButNotice">No. but got notice </label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="huLoseUtilityYes"
                             name="ppq_H_Utils_Shutoff_Fl"
                             formControlName="ppq_H_Utils_Shutoff_Fl"
                             value="Y">
                      <label for="huLoseUtilityYes">Yes, shut off</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </mat-card-content>
          </mat-card>
          <div class="row">
            &nbsp;
          </div>
        </div>

        <div>
          <mat-card class="mat-elevation-z8">
            <mat-card-header>
              <mat-card-title>Applicant Food</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <fieldset>

                <div class="questionText">
                  1. In the past 12 months did you run out of food?
                  <div class="row">
                    <div class="col-sm-3">
                      <input type="radio"
                             id="foodRunOutOfFoodNo"
                             name="ppq_Food_Past_Run_Outof_Fl"
                             formControlName="ppq_Food_Past_Run_Outof_Fl"
                             value="N">
                      <label for="foodRunOutOfFoodNo">No</label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="foodRunOutOfFoodYes"
                             name="ppq_Food_Past_Run_Outof_Fl"
                             formControlName="ppq_Food_Past_Run_Outof_Fl"
                             value="Y">
                      <label for="foodRunOutOfFoodYes">Yes</label>
                    </div>
                  </div>
                </div>

                <div class="questionText">
                  2. In the past 12 months did you worry about running out of food?
                  <div class="row">
                    <div class="col-sm-3">
                      <input type="radio"
                             id="foodWorryRunOutOfFoodNo"
                             name="ppq_Food_Future_Run_Outof_Fl"
                             formControlName="ppq_Food_Future_Run_Outof_Fl"
                             value="N">
                      <label for="foodWorryRunOutOfFoodNo">No</label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="foodWorryRunOutOfFoodYes"
                             name="ppq_Food_Future_Run_Outof_Fl"
                             formControlName="ppq_Food_Future_Run_Outof_Fl"
                             value="Y">
                      <label for="foodWorryRunOutOfFoodYes">Yes</label>
                    </div>
                  </div>
                </div>

              </fieldset>
            </mat-card-content>
          </mat-card>
          <div class="row">
            &nbsp;
          </div>
        </div>

        <div>
          <mat-card class="mat-elevation-z8">
            <mat-card-header>
              <mat-card-title>Applicant Transportation</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <fieldset>

                <div class="questionText">
                  1. Do you have access to reliable transportation? <br />
                </div>

                <div class="questionText">
                  <div class="row">
                    <div class="col-sm-4">
                      <input type="checkbox"
                             id="transICanDrive"
                             name="ppq_Tran_Drive_Have_Car_Fl"
                             formControlName="ppq_Tran_Drive_Have_Car_Fl">
                      <label for="transICanDrive"> Yes, I can drive and have a car </label>
                    </div>
                    <div class="col-sm-4">
                      <input type="checkbox"
                             id="transPublicTranportation"
                             name="ppq_Tran_Take_public_Tran_Fl"
                             formControlName="ppq_Tran_Take_public_Tran_Fl">
                      <label for="transPublicTranportation"> Yes, I can take public transportation </label>
                    </div>
                  </div>
                </div>

                <div class="questionText">
                  <div class="row">
                    <div class="col-sm-4">
                      <input type="checkbox"
                             id="transSomeoneCanDrive"
                             name="ppq_Tran_Someoneelse_Drive_Fl"
                             formControlName="ppq_Tran_Someoneelse_Drive_Fl">
                      <label for="transSomeoneCanDrive"> Yes, someone else can drive me </label>
                    </div>
                    <div class="col-sm-4">
                      <input type="checkbox"
                             id="transTroubleTransportation"
                             name="ppq_Tran_Trouble_Get_Tran_Fl"
                             formControlName="ppq_Tran_Trouble_Get_Tran_Fl">
                      <label for="transTroubleTransportation"> No, I have trouble getting transportation</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </mat-card-content>
          </mat-card>
          <div class="row">
            &nbsp;
          </div>
        </div>

        <div>
          <mat-card class="mat-elevation-z8">
            <mat-card-header>
              <mat-card-title>Applicant Social Isolation</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <fieldset>

                <div class="questionText">
                  1. I do not have anyone to talk to or be around <br />
                  <div class="row">
                    <div class="col-sm-3">
                      <input type="radio"
                             id="siHaveNoOneHardlyEver"
                             name="ppq_Social_Anyone_Talking_Fl"
                             formControlName="ppq_Social_Anyone_Talking_Fl"
                             value="H">
                      <label for="siHaveNoOneHardlyEver">Hardly Ever</label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="siHaveNoOneSometimes"
                             name="ppq_Social_Anyone_Talking_Fl"
                             formControlName="ppq_Social_Anyone_Talking_Fl"
                             value="S">
                      <label for="siHaveNoOneSometimes"> Some of the time </label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="siHaveNoOneOften"
                             name="ppq_Social_Anyone_Talking_Fl"
                             formControlName="ppq_Social_Anyone_Talking_Fl"
                             value="O">
                      <label for="siHaveNoOneOften"> Often </label>
                    </div>
                  </div>
                </div>

                <div class="questionText">
                  2. I feel left out of activities <br />
                  <div class="row">
                    <div class="col-sm-3">
                      <input type="radio"
                             id="siActivitiesHardlyEver"
                             name="ppq_Social_Left_Activity_Fl"
                             formControlName="ppq_Social_Left_Activity_Fl"
                             value="H">
                      <label for="siActivitiesHardlyEver"> Hardly Ever </label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="siActivitiesSometimes"
                             name="ppq_Social_Left_Activity_Fl"
                             formControlName="ppq_Social_Left_Activity_Fl"
                             value="S">
                      <label for="siActivitiesSometimes"> Some of the time </label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="siActivitiesOften"
                             name="ppq_Social_Left_Activity_Fl"
                             formControlName="ppq_Social_Left_Activity_Fl"
                             value="O">
                      <label for="siActivitiesOften"> Often </label>
                    </div>
                  </div>
                </div>

                <div class="questionText">
                  3. I feel isolated from other people <br />
                  <div class="row">
                    <div class="col-sm-3">
                      <input type="radio"
                             id="siIsolatedHardlyEver"
                             name="ppq_Social_Otherpeople_Fl"
                             formControlName="ppq_Social_Otherpeople_Fl"
                             value="H">
                      <label for="siIsolatedHardlyEver"> Hardly Ever </label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="siIsolatedSometimes"
                             name="ppq_Social_Otherpeople_Fl"
                             formControlName="ppq_Social_Otherpeople_Fl"
                             value="S">
                      <label for="siIsolatedSometimes"> Some of the time </label>
                    </div>
                    <div class="col-sm-3">
                      <input type="radio"
                             id="siIsolatedOften"
                             name="ppq_Social_Otherpeople_Fl"
                             formControlName="ppq_Social_Otherpeople_Fl"
                             value="O">
                      <label for="siIsolatedOften"> Often </label>
                    </div>
                  </div>
                </div>

              </fieldset>
            </mat-card-content>
          </mat-card>
          <div class="row">
            &nbsp;
          </div>
        </div>

        <div>
          <mat-card class="mat-elevation-z8">
            <mat-card-header>
              <mat-card-title>Other Help</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <fieldset>

                <div class="questionText">
                  1. Enter any other questions you have, or type of help you are seeking.
                  <div class="row">
                    <div class="col-sm-12">
                      <mat-form-field style="display: block !important;">
                        <textarea name="ppq_Other_Desc"
                                  formControlName="ppq_Other_Desc"
                                  placeholder="Enter Description"
                                  cols="120"
                                  matInput
                                  #ppq_Other_Desc
                                  maxlength="4000"
                                  rows="5"></textarea>
                        <mat-hint align="start">Maximum 4000 characters</mat-hint>
                        <mat-hint align="end">{{ ppq_Other_Desc.value.length }} / 4000</mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

              </fieldset>
            </mat-card-content>
          </mat-card>
          <div class="row">
            &nbsp;
          </div>
        </div>
        <div class="row">
          <ul class="pager buttonAlign">
            <li> <button mat-raised-button
                      color="primary"
                      type="button"
                      (click)="submitPortalQuestionaireForm()"
                      [disabled]="!portalQuestionaireForm.valid"
                      class='submitButton'>
                <span class="action-button-text">Submit</span>
              </button></li>
            <li><button mat-raised-button
                      color="warn"
                      type="button"
                      (click)="resetFormGroup()"
                      class='cancelButton'>
                <span class="action-button-text">Cancel</span>
              </button></li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</div>

<fieldset>
    <h2 *ngIf="data.title"
        mat-dialog-title
        class="d-flex justify-content-center">{{data.title}}</h2>
    <mat-dialog-content class="d-flex justify-content-center">
        <div [innerHtml]="data.content"></div>
    </mat-dialog-content>

    <div *ngIf="!data.confirm"
         mat-dialog-actions
         class="d-flex justify-content-center mt-3 buttonAlign">
      <button mat-raised-button
              color="primary"
              [mat-dialog-close]="true">OK</button>
    </div>

    <div *ngIf="data.confirm"
         mat-dialog-actions
         class="d-flex justify-content-center mt-3">
        <button mat-raised-button
                color="primary"
                [mat-dialog-close]="true">Yes</button>
        <button mat-raised-button
                color="warn"
                [mat-dialog-close]="false">No</button>
    </div>
</fieldset>

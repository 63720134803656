import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertsService } from '../services/alerts.service';
import { PortalQuestionaireService } from '../services/portal-questionaire.service';
import { ValidationExpressions } from '../validators/validation-common';


@Component({
  selector: 'app-portal-questionaire',
  templateUrl: './portal-questionaire.component.html',
  styleUrls: ['./portal-questionaire.component.css']
})
export class PortalQuestionaireComponent implements OnInit {

  @ViewChild('form') form: any;

  portalQuestionaireForm: FormGroup;
  isForSomeOne: boolean = false;
  isSubmitter: boolean = false;
  isApplicant: boolean = false;
  isEnableFunctionalNeeds: boolean = false;
  isEnableFNSomethingelseDesc: boolean = false;
  isEnableSexOther: boolean = false;
  isEnableRaceOther: boolean = false;
  isenableHULiveWithSomeoneElse: boolean = false;
  isEnableHULiveSomewhereElse: boolean = false;
  isEnableHUProblemSomethingElse: boolean = false;
  counties: any;
  states: any;



  constructor(private formBuilder: FormBuilder, private alertsService: AlertsService, private portalQuestionaireService: PortalQuestionaireService) {
    this.portalQuestionaireForm = this.formBuilder.group({
      ppq_Form_Submitter: new FormControl('self'),
      ppq_Callback_Name: new FormControl('applicant'),
      ppq_Firstname: ['', Validators.required],
      ppq_Lastname: ['', Validators.required],
      ppq_Phonenumber: ['', [Validators.pattern(ValidationExpressions.phone), Validators.required]],
      ppq_Email_Address: ['', [Validators.pattern(ValidationExpressions.email)]],
      ppq_Address: ['', Validators.required],
      ppq_Zip: ['', [Validators.required, Validators.pattern(/\d{5}/)]],
      ppq_City: [''],
      ppq_State: new FormControl(''),
      ppq_County: [''],

      ppq_Submitter_Firstname: [''],
      ppq_Submitter_Lastname: [''],
      ppq_Submitter_Phonenumber: ['', [Validators.pattern(ValidationExpressions.phone)]],
      ppq_Submitter_Email_Address: ['', [Validators.pattern(ValidationExpressions.email)]],
      ppq_Submitter_Address: new FormControl(''),
      ppq_Submitter_Zip: ['', Validators.pattern(/\d{5}/)],
      ppq_Submitter_City: new FormControl(''),
      ppq_Submitter_State: new FormControl(''),
      ppq_Submitter_County: new FormControl(''),
      ppq_Submitter_Relation: new FormControl(''),

      ppq_Age: new FormControl('', [Validators.min(1), Validators.max(120), Validators.pattern(/^[0-9]\d*$/)]),
      ppq_Sex: new FormControl(''),
      ppq_Sex_Other_Desc: new FormControl(''),
      ppq_Race_Ai_An_Fl: new FormControl(''),
      ppq_Race_Asian_Fl: new FormControl(''),
      ppq_Race_Black_Aa_Fl: new FormControl(''),
      ppq_Race_Nh_Op_Fl: new FormControl(''),
      ppq_Race_White_Fl: new FormControl(''),
      ppq_Race_Other_Fl: new FormControl(''),
      ppq_Race_Other_Desc: new FormControl(''),
      ppq_Ethn_Hispanic_Latino_Fl: new FormControl(''),
      ppq_Served_Miltary_Fl: new FormControl(''),

      ppq_Spoken_Language: new FormControl(''),
      ppq_Interpreter_Requsted_Fl: new FormControl(''),
      ppq_Trouble_Hearing_Fl: new FormControl(''),
      ppq_Trouble_Speaking_Fl: new FormControl(''),

      ppq_F_Need_Fl: new FormControl(''),
      ppq_F_Need_Bath_Shower_Fl: new FormControl(''),
      ppq_F_Need_Groom_Fl: new FormControl(''),
      ppq_F_Need_Going_Bathroom_Fl: new FormControl(''),
      ppq_F_Need_Eat_Feed_Fl: new FormControl(''),
      ppq_F_Need_Getting_Dressed_Fl: new FormControl(''),
      ppq_F_Need_Moving_House_Fl: new FormControl(''),
      ppq_F_Need_Cook_Prep_Meal_Fl: new FormControl(''),
      ppq_F_Need_Cleaning_House_Fl: new FormControl(''),
      ppq_F_Need_Money_Managing_Fl: new FormControl(''),
      ppq_F_Need_Make_Appt_Fl: new FormControl(''),
      ppq_F_Need_Taking_Medicatn_Fl: new FormControl(''),
      ppq_F_Need_Nursing_Serving_Fl: new FormControl(''),
      ppq_F_Need_Something_Else_Fl: new FormControl(''),
      ppq_F_Need_Something_Else_Desc: new FormControl(''),

      ppq_H_Utils_Where_Live_Fl: new FormControl(''),
      ppq_H_Utils_Where_Live_Desc: new FormControl(''),
      ppq_H_Utils_Who_Live_Fl: new FormControl(''),
      ppq_H_Utils_Who_Live_Desc: new FormControl(''),
      ppq_H_Utils_Loose_H_Fl: new FormControl(''),
      ppq_H_Utils_Bug_Infestion_Fl: new FormControl(''),
      ppq_H_Utils_Mold_Fl: new FormControl(''),
      ppq_H_Utils_Heat_Cool_Fl: new FormControl(''),
      ppq_H_Utils_Appliances_Nw_Fl: new FormControl(''),
      ppq_H_Utils_Smoke_Nw_Fl: new FormControl(''),
      ppq_H_Utils_Carbon_Nw_Fl: new FormControl(''),
      ppq_H_Utils_Plumbing_Fl: new FormControl(''),
      ppq_H_Utils_Accessibility_Fl: new FormControl(''),
      ppq_H_Utils_Somethingelse_Fl: new FormControl(''),
      ppq_H_Utils_Somethingelse_Desc: new FormControl(''),
      ppq_H_Utils_Shutoff_Fl: new FormControl(''),

      ppq_Food_Past_Run_Outof_Fl: new FormControl(''),
      ppq_Food_Future_Run_Outof_Fl: new FormControl(''),

      ppq_Tran_Drive_Have_Car_Fl: new FormControl(''),
      ppq_Tran_Take_public_Tran_Fl: new FormControl(''),
      ppq_Tran_Someoneelse_Drive_Fl: new FormControl(''),
      ppq_Tran_Trouble_Get_Tran_Fl: new FormControl(''),

      ppq_Social_Anyone_Talking_Fl: new FormControl(''),
      ppq_Social_Left_Activity_Fl: new FormControl(''),
      ppq_Social_Otherpeople_Fl: new FormControl(''),

      ppq_Other_Desc: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.portalQuestionaireService.getCounties().subscribe(
      counties => { this.counties = counties; });

    this.portalQuestionaireService.getStates().subscribe(
      states => { this.states = states; });

    this.disableFunctionalNeeds();
    this.onChanges();
  }

  onChanges(): void {
    this.portalQuestionaireForm.controls['ppq_Zip'].valueChanges.subscribe(zipcode => {
      if (zipcode != undefined) {
        let zip = { ZipCode: this.portalQuestionaireForm.controls['ppq_Zip'].value };
        if (zip.ZipCode.length == 5) {
          this.portalQuestionaireService.getCityAndCountyWithZip(zip).subscribe(
            cityState => {
              if (cityState.length > 0) {
                this.portalQuestionaireForm.controls['ppq_City'].setValue(cityState[0].city);
                this.portalQuestionaireForm.controls['ppq_County'].setValue(cityState[0].countyName);
                this.portalQuestionaireForm.controls['ppq_State'].setValue('OH')
              }
              else {
                this.ValidatePPQZipCode();
              }
            });
        } else {
          this.ValidatePPQZipCode();
        }
      }
      else {
        this.ValidatePPQZipCode();
      }
    });
  }


  private ValidatePPQZipCode() {
    this.portalQuestionaireForm.patchValue({ ppq_City: '' });
    this.portalQuestionaireForm.patchValue({ ppq_County: '' });
    this.portalQuestionaireForm.patchValue({ ppq_State: '' });
    let ppqZip = this.portalQuestionaireForm.get("ppq_Zip");
    ppqZip?.setErrors(null);
    ppqZip?.setErrors({ invalidZip: { msg: 'Please enter valid Ohio Zip Code' } });
  }

  displaySelfApplicantInfo() {
    this.isForSomeOne = false;
    this.isSubmitter = false;
    this.isApplicant = true;
    this.madateSubmitterInfo('isApplicant');
  }

  displaySomeOneApplicantInfo() {
    this.isForSomeOne = true;
    this.isSubmitter = false;
    this.isApplicant = true;
    this.portalQuestionaireForm.controls['ppq_Callback_Name'].setValue('applicant');
  }

  madateSubmitterInfo(callBackTo: string) {

    if (callBackTo === 'isSubmitter') {
      this.isSubmitter = true;
      this.isApplicant = false;

      this.portalQuestionaireForm.controls['ppq_Submitter_Firstname'].setValidators([Validators.required]);
      this.portalQuestionaireForm.controls['ppq_Submitter_Firstname'].updateValueAndValidity();

      this.portalQuestionaireForm.controls['ppq_Submitter_Lastname'].setValidators([Validators.required]);
      this.portalQuestionaireForm.controls['ppq_Submitter_Lastname'].updateValueAndValidity();

      this.portalQuestionaireForm.controls['ppq_Submitter_Phonenumber'].setValidators([Validators.required, Validators.pattern(ValidationExpressions.phone)]);
      this.portalQuestionaireForm.controls['ppq_Submitter_Phonenumber'].updateValueAndValidity();

      this.portalQuestionaireForm.controls['ppq_Submitter_Email_Address'].setValidators([Validators.pattern(ValidationExpressions.email)]);
      this.portalQuestionaireForm.controls['ppq_Submitter_Email_Address'].updateValueAndValidity();

      this.portalQuestionaireForm.controls['ppq_Submitter_Zip'].setValidators([Validators.pattern(/\d{5}/)]);
      this.portalQuestionaireForm.controls['ppq_Submitter_Zip'].updateValueAndValidity();
    }
    else if (callBackTo === 'isApplicant') {
      this.isSubmitter = false;
      this.isApplicant = true;

      this.portalQuestionaireForm.controls['ppq_Submitter_Firstname'].setValidators(null);
      this.portalQuestionaireForm.controls['ppq_Submitter_Firstname'].updateValueAndValidity();

      this.portalQuestionaireForm.controls['ppq_Submitter_Lastname'].setValidators(null);
      this.portalQuestionaireForm.controls['ppq_Submitter_Lastname'].updateValueAndValidity();

      this.portalQuestionaireForm.controls['ppq_Submitter_Phonenumber'].setValidators(null);
      this.portalQuestionaireForm.controls['ppq_Submitter_Phonenumber'].setValidators([Validators.pattern(ValidationExpressions.phone)]);
      this.portalQuestionaireForm.controls['ppq_Submitter_Phonenumber'].updateValueAndValidity();

      this.portalQuestionaireForm.controls['ppq_Submitter_Email_Address'].setValidators(null);
      this.portalQuestionaireForm.controls['ppq_Submitter_Email_Address'].setValidators([Validators.pattern(ValidationExpressions.email)]);
      this.portalQuestionaireForm.controls['ppq_Submitter_Email_Address'].updateValueAndValidity();

      this.portalQuestionaireForm.controls['ppq_Submitter_Zip'].setValidators(null);
      this.portalQuestionaireForm.controls['ppq_Submitter_Zip'].setValidators([Validators.pattern(/\d{5}/)]);
      this.portalQuestionaireForm.controls['ppq_Submitter_Zip'].updateValueAndValidity();
    }
  }

  enableFunctionalNeeds() {
    this.isEnableFunctionalNeeds = true;
    this.portalQuestionaireForm.controls['ppq_F_Need_Bath_Shower_Fl'].enable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Groom_Fl'].enable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Going_Bathroom_Fl'].enable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Eat_Feed_Fl'].enable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Getting_Dressed_Fl'].enable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Moving_House_Fl'].enable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Cook_Prep_Meal_Fl'].enable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Cleaning_House_Fl'].enable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Money_Managing_Fl'].enable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Make_Appt_Fl'].enable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Taking_Medicatn_Fl'].enable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Nursing_Serving_Fl'].enable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Something_Else_Fl'].enable();
  }

  disableFunctionalNeeds() {
    this.isEnableFunctionalNeeds = false;
    this.isEnableFNSomethingelseDesc = false;
    this.portalQuestionaireForm.controls['ppq_F_Need_Bath_Shower_Fl'].disable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Bath_Shower_Fl'].setValue(false);

    this.portalQuestionaireForm.controls['ppq_F_Need_Groom_Fl'].disable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Groom_Fl'].setValue(false);

    this.portalQuestionaireForm.controls['ppq_F_Need_Going_Bathroom_Fl'].disable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Going_Bathroom_Fl'].setValue(false);

    this.portalQuestionaireForm.controls['ppq_F_Need_Eat_Feed_Fl'].disable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Eat_Feed_Fl'].setValue(false);

    this.portalQuestionaireForm.controls['ppq_F_Need_Getting_Dressed_Fl'].disable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Getting_Dressed_Fl'].setValue(false);

    this.portalQuestionaireForm.controls['ppq_F_Need_Moving_House_Fl'].disable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Moving_House_Fl'].setValue(false);

    this.portalQuestionaireForm.controls['ppq_F_Need_Cook_Prep_Meal_Fl'].disable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Cook_Prep_Meal_Fl'].setValue(false);

    this.portalQuestionaireForm.controls['ppq_F_Need_Cleaning_House_Fl'].disable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Cleaning_House_Fl'].setValue(false);

    this.portalQuestionaireForm.controls['ppq_F_Need_Money_Managing_Fl'].disable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Money_Managing_Fl'].setValue(false);

    this.portalQuestionaireForm.controls['ppq_F_Need_Make_Appt_Fl'].disable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Make_Appt_Fl'].setValue(false);

    this.portalQuestionaireForm.controls['ppq_F_Need_Taking_Medicatn_Fl'].disable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Taking_Medicatn_Fl'].setValue(false);

    this.portalQuestionaireForm.controls['ppq_F_Need_Nursing_Serving_Fl'].disable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Nursing_Serving_Fl'].setValue(false);

    this.portalQuestionaireForm.controls['ppq_F_Need_Something_Else_Fl'].disable();
    this.portalQuestionaireForm.controls['ppq_F_Need_Something_Else_Fl'].setValue(false);

    this.portalQuestionaireForm.controls['ppq_F_Need_Something_Else_Desc'].setValue('');
  }

  enableFNSomethingelseDesc() {
    this.isEnableFNSomethingelseDesc = !this.isEnableFNSomethingelseDesc;
  }

  enableSexOther() {
    this.isEnableSexOther = true;
  }

  disableSexOther() {
    this.isEnableSexOther = false;
    this.portalQuestionaireForm.controls['ppq_Sex_Other_Desc'].setValue('');
  }

  enableRaceOther() {
    this.isEnableRaceOther = !this.isEnableRaceOther;
  }

  enableHULiveWithSomeoneElse() {
    this.isenableHULiveWithSomeoneElse = true;
  }

  disableHULiveWithSomeoneElse() {
    this.isenableHULiveWithSomeoneElse = false;
    this.portalQuestionaireForm.controls['ppq_H_Utils_Who_Live_Desc'].setValue('');
  }

  enableHULiveSomewhereElse() {
    this.isEnableHULiveSomewhereElse = true;
  }

  disableHULiveSomewhereElse() {
    this.isEnableHULiveSomewhereElse = false;
    this.portalQuestionaireForm.controls['ppq_H_Utils_Where_Live_Desc'].setValue('');
  }

  enableHUProblemSomethingElse() {
    this.isEnableHUProblemSomethingElse = !this.isEnableHUProblemSomethingElse;
  }

  // helper method
  getFormValidationErrors() {
    Object.keys(this.portalQuestionaireForm.controls).forEach(key => {

      const controlErrors: any = this.portalQuestionaireForm?.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }


 submitPortalQuestionaireForm() {
    this.getFormValidationErrors();
    if (this.portalQuestionaireForm.valid) {
      this.alertsService.showLoader();

      this.portalQuestionaireService.savePpqQuestionaire(this.portalQuestionaireForm.value).subscribe(
        data => {

          if (data) {
                    var successMsg = '';
            if(data.values.ppq_ab_agency_name_out != 'null')
{
           successMsg = `Thank you for completing this form!  Someone from 
              [${data.values.ppq_ab_agency_name_out}, 1-866-243-5678] 
              will contact the person within 3 business days.`;
//} 

            this.alertsService.ShowMessage(successMsg, 'Success!').subscribe(yes => {
              if (yes) {
                this.resetFormGroup();
                this.alertsService.clearLoader();
              }
            });
}
else
{
           successMsg = `Duplicate entry. An LTSSI application has already been submitted for this applicant within the last 10 minutes.`;
//} 

            this.alertsService.ShowMessage(successMsg, 'Warning!').subscribe(yes => {
              if (yes) {
                this.resetFormGroup();
                this.alertsService.clearLoader();
              }
            });
}
          }
        },
        err => {
          alert('Record not saved successfully');
        }
      );
    }
  }

  resetFormGroup() {
    this.form.resetForm();
    this.portalQuestionaireForm.controls['ppq_Form_Submitter'].setValue('self');
    this.portalQuestionaireForm.controls['ppq_Callback_Name'].setValue('applicant');
    this.isForSomeOne = false;
    this.isSubmitter = false;
    this.isApplicant = false;
    this.isEnableFunctionalNeeds = false;
    this.isEnableFNSomethingelseDesc = false;
    this.isEnableSexOther = false;
    this.isEnableRaceOther = false;
    this.isenableHULiveWithSomeoneElse = false;
    this.isEnableHULiveSomewhereElse = false;
    this.isEnableHUProblemSomethingElse = false;
    window.scrollTo(0, 0);
  }

}

import { Component, OnInit } from '@angular/core';
import { PortalQuestionaireService } from '../services/portal-questionaire.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public headerText: string="";
  public env: string="";

  constructor(private portalQuestionaireService: PortalQuestionaireService) {}

  ngOnInit(): void {
    this.headerText = "Long Term Services and Supports Inquiry ";
    //this.env = "test";
    this.portalQuestionaireService.getEnv().subscribe(env => { this.env = env; });
  }
 
}

<link href="//netdna.bootstrapcdn.com/bootstrap/3.0.3/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//netdna.bootstrapcdn.com/bootstrap/3.0.3/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>
<div>
    <div class="container">
      <div class="row">
        <span class="col-lg-6">
          <img class="logo" src="../../../assets/images/OBLTSS.png" alt="aging.ohio.gov" />
        </span>
        <span class="col-lg-6">
            <h2 class="header-text"><b>{{headerText}}</b> <b class="alert-info">{{env}}</b></h2>
        </span>
      </div>
    </div>
    <div class="header-ribbon">
    </div>
  </div>

<link href="//netdna.bootstrapcdn.com/bootstrap/3.0.3/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//netdna.bootstrapcdn.com/bootstrap/3.0.3/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>

<!--class="navbar-fixed-bottom"-->
<footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 left">
          <p>
            &copy; Copyright 2021 by Ohio Department of Aging - OBLTSS version 2.0.0.0
            &nbsp;
            <span class="vl"></span>
            &nbsp;
            <a href="http://ohio.gov/policies/">Privacy Notice</a>
          </p>
  
        </div>
        <div class="col-lg-4 right">
          <a href="http://ohio.gov"><img src="../../assets/images/OH_OhioGovLogo_White.png" /></a>
        </div>
        <input type="hidden" name="Version" value="2.0.7">
      </div>
  
  
    </div>
  </footer>
